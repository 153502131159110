<template>
  <div>
    <!-- <Snow :active="true" speed="l" color="#0046d5" /> -->
  </div>
</template>

<script setup>
import Snowflakes from "magic-snowflakes";

var snowflakes;

onMounted(() => {
  snowflakes = new Snowflakes({
    maxOpacity: 0.95,
    minSize: 10,
    rotation: true,
    wind: true,
    zIndex: 100,
    autoResize: true,
  });
});

// onUnmounted(() => snowflakes.destroy());
</script>

<style></style>
